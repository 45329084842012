import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {

    // The values that are defined here are the default values that can
    // be overridden by env.js


    
    //public enableDebug = true
     
    public apiBaseUrl;
     


    constructor() {

    }
}
