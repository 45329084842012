import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { OK, INTERNAL_SERVER_ERROR, UNAUTHORIZED, NOT_FOUND, FORBIDDEN, UNPROCESSABLE_ENTITY } from 'http-status-codes'
import { Observable, of } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { mergeMap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(private authorize: AuthorizeService, private notification: NzNotificationService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    return this.authorize.getAccessToken()
      .pipe(
        mergeMap(token => this.processRequestWithToken(token, req, next)),
        catchError((error: HttpErrorResponse, caught) => {
          if (error.status === 401) {


            this.router.navigate(ApplicationPaths.LoginPathComponents, {
              queryParams: {
                [QueryParameterNames.ReturnUrl]: this.router.url
              }
            });
          }
            //intercept the respons error and displace it to the console
            this.handleError(error);
          
          return of(error);
        }) as any

      );
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string, req: HttpRequest<any>, next: HttpHandler) {
    //console.log(req);
    if (!!token && this.isSameOriginUrl(req)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }

  private isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }


  //blobToText(blob: any): Observable<string> {
  //  return new Observable<string>((observer: any) => {
  //    if (!observer) {
  //      console.log('blobToText: observer is undefined');
  //    }
  //    try {
  //      if (!blob) {
  //        observer.next("");
  //        observer.complete();
  //      } else {
  //        let reader = new FileReader();
  //        reader.onload = function (e) {
  //          console.log( reader.result);
  //        }
  //        //reader.onload = event => {
  //        //  observer.next((<any>event.target).result);
  //        //  observer.complete();
  //        //};
  //        //reader.readAsText(reader.result);
  //      }
  //    }
  //    catch (ex) {
  //      console.error('blobToText', ex.message);
  //    }
  //  });
  //}
  
 blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
  }

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  /**
 * manage errors
 * @param response
 * @returns {any}
 */
  private handleError(response: HttpErrorResponse): Observable<any> {
    
     this.blobToText(response.error).pipe(mergeMap(_responseText => {
      
      let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
      
      return of(resultData200);
     })).subscribe(error => {
     
      //const error = JSON.parse(data);
      
      if (error.statusCode === 403) {

        this.router.navigate(['admin/forbidden'])
        return;
      }

      if (error.statusCode === 403) {

        this.router.navigate(['admin/forbidden'])
      }

      if (response.status === 412) {

        this.showErrorNotification(error.errorMessage);
        throw response;
      }
      console.log(response.message);
      if (error.statusCode === 547) {
        //The server encountered an enternal error or misconfiguration and was unable to complete your request
        this.showErrorNotification(error.errorMessage);

        return of(response.message);
      }
      if (error.statusCode === UNPROCESSABLE_ENTITY) {
        this.showErrorNotification(error.errorMessage);
        //for (const message in JSON.parse(error.errorMessage)) {
        //  this.messageService.showError(JSON.parse(error.errorMessage)[message.toString()]);
        //  this.showErrorNotification(JSON.parse(error.errorMessage)[message.toString()]);
        //}
        //  this.messageService.showError(error.errorMessage[i]);

        return of(response.message);
      }
      if (response.status === INTERNAL_SERVER_ERROR) {
        //The server encountered an enternal error or misconfiguration and was unable to complete your request
        if (error.errorMessage.match("already entered")) {
          //this.messageService.showError("Already entered, please enter new.");
          this.showErrorNotification(error.errorMessage);
        }
        else {
          //this.messageService.showError('The server encountered an enternal error.ERROR: ' + error.errorMessage);
          this.showErrorNotification(error.errorMessage);
        }

        return of(response.message);
      }
      if (response.status === FORBIDDEN) {
        //The server encountered an enternal error or misconfiguration and was unable to complete your request
        //this.messageService.showError('The server encountered an enternal error.ERROR: ' + error.errorMessage);
        this.showErrorNotification(error.errorMessage);
        return of(response.message);
      }

      if (response.status === NOT_FOUND) {
        //this.messageService.showError('The server encountered an enternal error. ERROR: ' + error.errorMessage);
        this.showErrorNotification(error.errorMessage);
        return of(response.message);
      }



      if (error.errorMessage !== null || error.errorMessage !== undefined || error.errorMessage === '') {

        //this.messageService.showError('The server encountered an enternal error. ERROR: ' + error.errorMessage);
        this.showErrorNotification(error.errorMessage);
      }
    });

    throw response;
  }

  private showErrorNotification(msg: string): void {

    this.notification.config({
      nzPlacement: 'bottomRight'
    });

    this.notification.create(
      'error',
      'Error',
      msg
    );
  }
}
