import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
 

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent {
  contentClass: any = 'not-submitted'
  model: any = {};
  memberSelectList = [{ 'id': undefined, 'name': undefined }, { 'id': 1, 'name': 'ABC' }, { 'id': 2, 'name': 'XYZ' }];
  membershipTypeId: any;
  approvedDate = '12/12/2008';
  value: string;
  defaultPicUrl: string = 'images/BaghaLogo.jpg'
  array = ['images/slider1.jpg', 'images/slider2.jpg', 'images/slider3.jpg', 'images/slider4.jpg'];

  //chart
  title = 'Inward Clearing';
  seriesName = 'Inward Status';
  isTimerOn = true;
  othersTimerOn = false;
  highValueCutoffTime = "11:20";
  highValueReturnCutoffTime = "17:30";
  regularValueCutoffTime = "14:20";
  regularValueReturnCutoffTime = "18:30";

  //selectedItem: any = {};
  constructor() {
    this.model.surname = undefined;
    this.model.membershipTypeId = undefined;
    this.model.approvedDate = undefined;
    //console.log('aaa', this.title);
  }
  register(myForm: NgForm) {
    //console.log('Successful registration');
    //console.log(myForm);
  }
  onSubmit(myForm: NgForm) {
    this.contentClass = 'submitted'
    //console.log('onSubmit', myForm.valid);
     

  }
  onBtnClick() {
    this.highValueCutoffTime = "12:20";
    this.highValueReturnCutoffTime = "17:30";
    this.regularValueCutoffTime = "14:20";
    this.regularValueReturnCutoffTime = "18:30";
    //console.log("highValueCutoffTime", this.highValueCutoffTime);
  }
}
