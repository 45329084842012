import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { EncryptDataComponent } from './encrypt-data/encrypt-data.component';

const routes: Routes = [
  //{ path: '', component: HomeComponent, pathMatch: "full" },
  { path: '', redirectTo: 'admin', pathMatch: "full" },
  //{ path: '', pathMatch: "full" },
  //{ path: 'admin', loadChildren: './cts-admin/cts-admin.module#CtsAdminModule', canActivate: [AuthorizeGuard] },
  {
    path: 'admin', loadChildren: () => import('./cts-admin/cts-admin.module').then(m => m.CtsAdminModule), canActivate: [AuthorizeGuard]

  },
  {
    path: 'cryptography', component: EncryptDataComponent, pathMatch: "full"
  },
  {
    path: 'admin/new-info', loadChildren: () => import('./new-info/new-info.module').then(m => m.NewInfoModule), canActivate: [AuthorizeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
 
}
