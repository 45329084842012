
import { NgModule } from '@angular/core';

import { NgZorroAntdModule, NZ_ICONS } from 'ng-zorro-antd';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzMessageModule } from 'ng-zorro-antd/message';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import { IconDefinition } from '@ant-design/icons-angular';
import en from '@angular/common/locales/en';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { Component } from '@angular/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  imports: [

  ], exports: [
    NgZorroAntdModule
    , NzTreeSelectModule
    , NzInputModule
    , NzInputNumberModule
    , NzTreeModule
    , NzDatePickerModule
    , NzButtonModule
    , NzSelectModule
    , NzAvatarModule
    , NzCalendarModule
    , NzMessageModule
    , NzCarouselModule
    , NzDrawerModule

  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }],
})
export class AngulerNgzorroModule {
}
