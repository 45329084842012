import { Component } from '@angular/core';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public spinkit = Spinkit;
  title = 'app';


  filteredUrlPatterns = ['silent-renew.html'
                            , '/.well-known/openid-configuration/jwks'
                            , '/api/Dashboard/*'
                            , '/api/ApplicationService/Get'
                            ]

  constructor(private routerSvc: Router
    , private titleService: Title
    , private spinner: SpinnerVisibilityService) {


    this.routerSvc.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.spinner.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.spinner.hide();
      }
    });
  }
}
