import { Component } from '@angular/core';
import { CryptographyClient } from '../asd-cts-api';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'cts-encrypt-data',
  templateUrl: './encrypt-data.component.html',
  styleUrls: ['./encrypt-data.component.scss']
})
/** encrypt-data component*/
export class EncryptDataComponent {
  /** encrypt-data ctor */
  encryptSendName: string = "";
  encryptGetName: string = "";
  decryptSendName: string = "";
  decryptGetName: string = "";
  crypto: string = "";
  constructor(public cryptoService: CryptographyClient) {

  }

  getEncryptedName(): void {
    if (!isNullOrUndefined(this.encryptSendName) && this.encryptSendName !== '') {
      this.cryptoService.encrypt(this.encryptSendName).subscribe(result => {
        this.encryptGetName = result;
      });
    }
    else {
      alert("Plain Text Cannot Empty");
    }
  }

  getDecryptedName(): void {
    if (!isNullOrUndefined(this.decryptSendName) && this.decryptSendName !== '') {
      this.cryptoService.decrypt(this.decryptSendName).subscribe(result => {
        this.decryptGetName = result;
      });
    }
    else {
      alert("Cipher Text Cannot Empty");
    }
  }


  getEncrypt(): void {
    this.clearAll();
    this.crypto = "encrypt";

  }

  getDecrypt(): void {
    this.clearAll();
    this.crypto = "decrypt";

  }

  clearAll(): void {
    this.encryptGetName = "";
    this.encryptSendName = "";
    this.decryptSendName = "";
    this.decryptGetName = "";
  }
}
