import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NavTopMenuComponent } from './nav-top-menu/nav-top-menu.component';
 


import { API_BASE_URL, ApplicationSettingsClient, DashboardClient, CryptographyClient} from './asd-cts-api';



import { ModalModule } from 'ngx-bootstrap/modal';
import { AppIconsModule } from './app.icons.module';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { environment } from '../environments/environment';
//import { AngularMaterialModule } from './angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ApplicationSharedModule } from './application-shared/application-shared.module';
import { RouterModule } from '@angular/router';
//import { AngularPrimengModule } from './angular-primeng.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
 
import { HomeComponent } from './home/home.component';
import { AngulerNgzorroModule } from './anguler-ngzorro.module';

import { CalendarModule, DateAdapter } from 'angular-calendar';
 
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//import { AngularHighchartModule } from './angular-highchart.module';
//import { AnalogClockComponent } from './application-shared/components/analog-clock/analog-clock.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
//import { ChartComponent } from './application-shared/components/chart/chart.component';
import { SignalService } from './application-shared/services/signal.service';
import { EnvServiceProvider } from './env.service.provider';
import { EnvService } from './env.service';
import { EncryptDataComponent } from './encrypt-data/encrypt-data.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export function getBaseUrl(env: EnvService) : string {
  //return () =>
  //    oidcConfigService.load_using_custom_stsServer(
  //        environment.identityUrl
  //    );
  //console.log(env);
  return env.apiBaseUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    NavTopMenuComponent,

     
    //AnalogClockComponent,
    HomeComponent,
    EncryptDataComponent,
    
    
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InfiniteScrollModule,

    //AppIconsModule,
    AppRoutingModule,
    //AngularMaterialModule,
    //AngularPrimengModule,
    AngulerNgzorroModule,
    //AngularHighchartModule,
    ApiAuthorizationModule,
    //ApplicationSharedModule,
    
    ModalModule.forRoot(),

    BrowserAnimationsModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    //SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' })
    
  ],
  entryComponents: [
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: API_BASE_URL, useFactory: getBaseUrl, deps: [EnvService] },
    DatePipe,
    CurrencyPipe,
    HomeComponent,
    ApplicationSettingsClient,
    DashboardClient,
    SignalService,
     
    
    CryptographyClient,
    BnNgIdleService,
    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: loadConfig,
    //  deps: [EnvService],
    //  multi: true
    //},
    EnvServiceProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
   
}
