import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from "@aspnet/signalr";
 
import { environment } from '../../../environments/environment';
import { EnvService } from '../../env.service';
 
@Injectable({
  providedIn: "root"
})
export class SignalService {

  //private defaultUrl: string = "https://localhost:44376/";

  private defaultUrl = this.environment.apiBaseUrl + '/';

  private hubConnection: signalR.HubConnection;
  //signalReceived = new EventEmitter<SignalInputModel>();

  //#region Events Emitter

  //#region Outward
  OceSummaryReceived = new EventEmitter<any>();
  OceUpsertReceived = new EventEmitter<any>();
  OceChequeScanReceived = new EventEmitter<any>();
  OceDataEntryReceived = new EventEmitter<any>();
  OceAuthorizedReceived = new EventEmitter<any>();
  OceApprovedReceived = new EventEmitter<any>();
  OceDiscardReceived = new EventEmitter<any>();
  OceRejectedReceived = new EventEmitter<any>();
  OceModifyReceived = new EventEmitter<any>();
  OceInwardReturnedReceived = new EventEmitter<any>();
  //#endregion

  //#region Inward
  IceSummaryReceived = new EventEmitter<any>();
  IceUpsertReceived = new EventEmitter<any>();
  IceChequeReceived = new EventEmitter<any>();
  IceChequeCBSPendingReceived = new EventEmitter<any>();
  IceTechnicalReceived = new EventEmitter<any>();
  IceSignatureReceived = new EventEmitter<any>();
  IceReferredReceived = new EventEmitter<any>();
  IceAcceptedReceived = new EventEmitter<any>();
  IceOutwardReturnedReceived = new EventEmitter<any>();
  IcePositivePayReceived = new EventEmitter<any>();
  //#endregion

  //#region House Transfer
  HTSummaryReceived = new EventEmitter<any>();
  HTChequeScanReceived = new EventEmitter<any>();
  HTUpsertReceived = new EventEmitter<any>();
  HTAuthorizedReceived = new EventEmitter<any>();
  HTApprovedReceived = new EventEmitter<any>();
  HTDiscardReceived = new EventEmitter<any>();
  HTTechnicalReceived = new EventEmitter<any>();
  HTSignatureReceived = new EventEmitter<any>();
  HTReferredReceived = new EventEmitter<any>();
  HTAcceptedReceived = new EventEmitter<any>();
  HTReturnedReceived = new EventEmitter<any>();
  HTCbsPostReceived = new EventEmitter<any>();
  HTChargeApplyReceived = new EventEmitter<any>();
  // #endregion

  //#endregion

  constructor(private environment: EnvService) {
    //console.log('Base Url', this.environment);
    this.buildConnection();
    this.startConnection();
  }

  private buildConnection = () => {
    //console.log("trying connection!");
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.defaultUrl + "signalHub") //use your api adress here and make sure you use right hub name.
      .build();
  };


  public stopConnection() {
    //this.hubConnection.off('OceUpsertMessage');
    this.hubConnection.stop().then(() => {
       console.log("Connection stopped.");
    });
   
  }


  private startConnection = () => {
    this.hubConnection
      .start()
      .then(() => {
        //console.log("Connection Started...");
        this.callConnecionEvents();
      })
      .catch(err => {
        //console.log("Error while starting connection: " + err);

        //if you get error try to start connection again after 3 seconds.
        setTimeout(function () {
          this.startConnection();
        }, 3000);
      });
  };

  callConnecionEvents() {


    //#region Outward
    this.OceSummaryEvents();
    this.OceChequeScanEvents();
    this.OceupsertEvents();
    this.OceDataEntryEvents();
    this.OceAuthorizedEvents();
    this.OceApprovedEvents();
    this.OceDiscardEvents();
    this.OceRejectedEvents();
    this.OceModifyEvents();
    this.OceInwardReturnedEvents();
    //#endregion

    //#region Inward
    this.IceSummaryEvents();
    this.IceUpdateEvents();
    this.IceChequeEvents();
    this.IceChequeCBSPendingEvents();
    this.IceTechnicalEvents();
    this.IceSignatureEvents();
    this.IceReferredEvents();
    this.IceAcceptedEvents();
    this.IceReturnedEvents();
    this.IcePositivePayEvents();
    //#endregion

    //#region House Transfer
    this.HTSummaryEvents();
    this.HTChequeScanEvents()
    this.HTDetailUpdateEvents();
    this.HTAuthorizedEvents();
    this.HTApprovedEvents();
    this.HTDiscardEvents();
    this.HTTechnicalEvents();
    this.HTSignatureEvents();
    this.HTReferredEvents();
    this.HTAcceptedEvents();
    this.HTReturnedEvents();
    this.HTCbsPostEvents();
    this.HTChargeApplyEvents
    //#endregion

  }


  //#region Outward
  //Oce Summary
  private OceSummaryEvents() {
    this.hubConnection.on("OceSummaryMessage", (data: any) => {
      //console.log("Oce Summary Received", data);
      this.OceSummaryReceived.emit(data);
    });
  }

  //Oce Upsert
  private OceupsertEvents() {
    this.hubConnection.on("OceDataEntryMessage", (data: any) => {
      console.log("Oce Upsert Received", data);
      this.OceDataEntryReceived.emit(data);
    });
  }

  private OceChequeScanEvents() {
    this.hubConnection.on("OceChequeScan", (data: any) => {
      console.log("Oce Cheque Scan Received", data);
      this.OceChequeScanReceived.emit(data);
    });
  }

  //Oce Upsert
  private OceDataEntryEvents() {
    this.hubConnection.on("OceUpsertMessage", (data: any) => {
      console.log("Oce Upsert Received", data);
      this.OceUpsertReceived.emit(data);
    });
  }

  //Oce Authorized
  private OceAuthorizedEvents() {
    this.hubConnection.on("OceAuthorizedMessage", (data: any) => {
      console.log("Oce Authorized Received", data);
      this.OceAuthorizedReceived.emit(data);
    });
  }

  //Oce Approved
  private OceApprovedEvents() {
    this.hubConnection.on("OceApprovedMessage", (data: any) => {
      console.log("Oce Approved Received", data);
      this.OceApprovedReceived.emit(data);
    });
  }

  //Oce Discard
  private OceDiscardEvents() {
    this.hubConnection.on("OceDiscardMessage", (data: any) => {
      console.log("Oce Discard Received", data);
      this.OceDiscardReceived.emit(data);
    });
  }

  //Oce Rejected
  private OceRejectedEvents() {
    this.hubConnection.on("OceRejectedMessage", (data: any) => {
      console.log("Oce Rejected Received", data);
      this.OceRejectedReceived.emit(data);
    });
  }

  //Oce Modify
  private OceModifyEvents() {
    this.hubConnection.on("OceModifyMessage", (data: any) => {
      console.log("Oce Modify Received", data);
      this.OceModifyReceived.emit(data);
    });
  }

  //Oce Inward Returned
  private OceInwardReturnedEvents() {
    this.hubConnection.on("OceInwardReturnedMessage", (data: any) => {
      console.log("Oce Inward Returned Received", data);
      this.OceInwardReturnedReceived.emit(data);
    });
  }
  //#endregion

  //#region Inward

  //ICE Summary
  private IceSummaryEvents() {
    this.hubConnection.on("IceSummaryMessage", (data: any) => {
      //console.log("Ice Summary Received", data);
      this.IceSummaryReceived.emit(data);
    });
  }

  //ICE Update
  private IceUpdateEvents() {
    this.hubConnection.on("IceUpdateMessage", (data: any) => {
      //console.log("Ice Update Received", data);
      this.IceUpsertReceived.emit(data);
    });
  }

  //ICE Cheque
  private IceChequeEvents() {
    this.hubConnection.on("IceChequeMessage", (data: any) => {
      //console.log("Ice Authorized Received", data);
      this.IceChequeReceived.emit(data);
    });
  }

  //ICE Cheque CBS Pending
  private IceChequeCBSPendingEvents() {
    this.hubConnection.on("IceChequeCBSPendingMessage", (data: any) => {
      //console.log("Ice Authorized Received", data);
      this.IceChequeCBSPendingReceived.emit(data);
    });
  }

  //Ice Technical
  private IceTechnicalEvents() {
    this.hubConnection.on("IceTechnicalMessage", (data: any) => {
      //console.log("Ice Technical Received", data);
      this.IceTechnicalReceived.emit(data);
    });
  }

  //Ice Signature
  private IceSignatureEvents() {
    this.hubConnection.on("IceSignatureMessage", (data: any) => {
      //console.log("Ice Signature Received", data);
      this.IceSignatureReceived.emit(data);
    });
  }

  //Ice Referred
  private IceReferredEvents() {
    this.hubConnection.on("IceReferredMessage", (data: any) => {
      //console.log("Ice Referred Received", data);
      this.IceReferredReceived.emit(data);
    });
  }

  //Ice Accepted
  private IceAcceptedEvents() {
    this.hubConnection.on("IceAcceptedMessage", (data: any) => {
      //console.log("Ice Accepted Received", data);
      this.IceAcceptedReceived.emit(data);
    });
  }

  //Ice Returned
  private IceReturnedEvents() {
    this.hubConnection.on("IceOutwardReturnedMessage", (data: any) => {
      //console.log("Ice Outward Returned Received", data);
      this.IceOutwardReturnedReceived.emit(data);
    });
  }

  //Ice PP
  private IcePositivePayEvents() {
    this.hubConnection.on("IcePositivePayMessage", (data: any) => {
      //console.log("Ice Outward Returned Received", data);
      this.IcePositivePayReceived.emit(data);
    });
  }

  //#endregion

  //#region House Transfer

  //HT Summary
  private HTSummaryEvents() {
    this.hubConnection.on("HTSummaryMessage", (data: any) => {
      //console.log("HT Summary Received", data);
      this.HTSummaryReceived.emit(data);
    });
  }
  //HT Detail Update
  private HTChequeScanEvents() {
    this.hubConnection.on("HteChequeScan", (data: any) => {
      //console.log("HT Update Received", data);
      this.HTChequeScanReceived.emit(data);
    });
  }
  //HT Detail Update
  private HTDetailUpdateEvents() {
    this.hubConnection.on("HTUpdateMessage", (data: any) => {
      //console.log("HT Update Received", data);
      this.HTUpsertReceived.emit(data);
    });
  }

  //HT Authorized
  private HTAuthorizedEvents() {
    this.hubConnection.on("HTAuthorizedMessage", (data: any) => {
      //console.log("HT Authorized Received", data);
      this.HTAuthorizedReceived.emit(data);
    });
  }

  //HT Approved
  private HTApprovedEvents() {
    this.hubConnection.on("HTApprovedMessage", (data: any) => {
      //console.log("HT Approved Received", data);
      this.HTApprovedReceived.emit(data);
    });
  }

  //HT Discard
  private HTDiscardEvents() {
    this.hubConnection.on("HTDiscardMessage", (data: any) => {
      //console.log("HT Discard Received", data);
      this.HTDiscardReceived.emit(data);
    });
  }

  //HT Technical
  private HTTechnicalEvents() {
    this.hubConnection.on("HTTechnicalMessage", (data: any) => {
      //console.log("HT Technical Received", data);
      this.HTTechnicalReceived.emit(data);
    });
  }

  //HT Signature
  private HTSignatureEvents() {
    this.hubConnection.on("HTSignatureMessage", (data: any) => {
      //console.log("HT Signature Received", data);
      this.HTSignatureReceived.emit(data);
    });
  }

  //HT Referred
  private HTReferredEvents() {
    this.hubConnection.on("HTReferredMessage", (data: any) => {
      //console.log("HT Referred Received", data);
      this.HTReferredReceived.emit(data);
    });
  }

  //HT Accepted
  private HTAcceptedEvents() {
    this.hubConnection.on("HTAcceptedMessage", (data: any) => {
      //console.log("HT Accepted Received", data);
      this.HTAcceptedReceived.emit(data);
    });
  }

  //HT Returned
  private HTReturnedEvents() {
    this.hubConnection.on("HTReturnedMessage", (data: any) => {
      //console.log("HT Returned Received", data);
      this.HTReturnedReceived.emit(data);
    });
  }

  //HT CbsPost
  private HTCbsPostEvents() {
    this.hubConnection.on("HTCbsPostMessage", (data: any) => {
      //console.log("HT CbsPost Received", data);
      this.HTCbsPostReceived.emit(data);
    });
  }

  //HT ChargeApply
  private HTChargeApplyEvents() {
    this.hubConnection.on("HTChargeApplyMessage", (data: any) => {
      //console.log("HT ChargeApply Received", data);
      this.HTChargeApplyReceived.emit(data);
    });
  }

  //#endregion


}
